<template>
	<v-text-field
		:value="value" :dense="dense"
		outlined rounded
		:clearable="clearable"
		:disabled="disabled"
		:label="label.toUpperCase()"
		placeholder="Start typing"
		:type="type" :hint="hint"
		:counter="counter" :readonly="readonly"
		hide-details="auto"
		:error-messages="getErrorMessage"
		:prepend-inner-icon="icon"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
		@keyup="$emit('keyup', $event)"
	/>
</template>
<script>
import AdminFieldErrorMessage from "@/mixins/FormFieldError.js";

export default {
	name: "TextField",
	mixins: [AdminFieldErrorMessage],
	props: {
		value: {
			required: true
		},
		name: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true
		},
		hint: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			required: false,
			default: "text"
		},
		errors: {
			type: Object,
			required: false,
			default: () => {}
		},
		icon: {
			type: String,
			required: true
		},
		counter: {
			default: false
		},
		dense: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	emits: ["input", "change"],
}
</script>
<style lang="scss" scoped>
::v-deep.v-input {
	.v-input__control {
		.v-input__slot {
			padding: 0 24px;
		}
	}
}
</style>
